import DropDownMenu from "./DropDownMenu";
import { NavLink, Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
// import logoDark from "../assets/logo-light.png";
// import logoBlack from "../assets/logo-light.png";
import logoLight from "../assets/logo-light.png";
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
function Navbar() {
  const { pathname } = useLocation();
  const AuthState = useAuthState();
  const currentMode = localStorage.getItem("theme");
  const [currentTheame, setCurrentTheame] = useState(currentMode);
  //dark mode
  const navigate = useNavigate();

  const docElement = document.documentElement;
  if (currentMode === "dark") {
    docElement.classList.add("dark");
  }

  function handleToggleTheme(e) {
    if (e.target.checked) {
      docElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
      setCurrentTheame("dark");
    } else {
      docElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
      setCurrentTheame("light");
    }
  }
  gsap.registerPlugin(Flip);

  const app = useRef();

  const [scroll, setScroll] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScroll(true);
      } else setScroll(false);
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      ref={app}
      className={`fixed inset-0 z-50 h-28 w-full  ${
        scroll ? "bg-secondary text-light" : "text-secondary"
      } transitio duration-700 ease-in-out  `}
    >
      {/**
       * //!Fixed mobile nav
       */}
      <div
        className={`fixed-mobile-nav fixed bottom-0 top-28 ${
          toggle ? "right-0" : "right-[-100%]"
        } h-mobile z-[100]  hidden w-full flex-col items-center justify-center bg-blue-200 text-secondary  duration-500   dark:bg-dark dark:text-light   amd:flex  `}
      >
        <div className="dark-mode-close-btns w-full flex items-center justify-evenly pt-16">
          {AuthState.userData?.student_token && (
            <div className="hidden sm:block">
              <label className="switch cursor-pointer ">
                <span className="sun">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g>
                      <circle r="5" cy="12" cx="12"></circle>
                      <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
                    </g>
                  </svg>
                </span>
                <span className="moon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
                  </svg>
                </span>
                <input
                  type="checkbox"
                  className="dark-mode-checkbox"
                  value="dark"
                  onChange={handleToggleTheme}
                  defaultChecked={currentMode === "dark"}
                />
                <span className="slider"></span>
              </label>
            </div>
          )}

          <div className="close-icon  ">
            <FontAwesomeIcon
              onClick={() => setToggle(!toggle)}
              className="hidden   cursor-pointer text-6xl transition-all duration-300 ease-in-out hover:scale-110 active:scale-90 amd:block"
              icon={faXmark}
            />
          </div>
        </div>
        <ul className="nav-links flex h-full   w-full flex-col-reverse items-center  justify-center gap-16 ">
          {!AuthState.userData?.student_token && (
            <>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/register"> Join Now</Link>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/signin"> Sign In</Link>
                </li>
              </div>

              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <a href="#instructor">About us</a>
                </li>
              </div>

              <div className="nav-item relative  ">
                <li
                  className=" text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <a href="#hero">Home</a>
                </li>
              </div>
            </>
          )}
          {AuthState.userData?.student_token && (
            <>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/exams">Exams</Link>
                </li>
              </div>
              {/* <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/home/results">Results</Link>
                </li>
              </div> */}
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/lectures">Lectures</Link>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/home">Home</Link>
                </li>
              </div>
            </>
          )}
        </ul>
      </div>
      {/**
       * //!Desktop nav
       */}
      <nav className="width flex h-full items-center justify-between gap-3">
        <FontAwesomeIcon
          className={`burger-icon hidden   cursor-pointer text-5xl transition-all duration-300 ease-in-out hover:scale-110 active:scale-90 amd:block `}
          icon={faBars}
          onClick={() => setToggle(!toggle)}
        />

        <div
          onClick={() => {
            setToggle(false);
          }}
          className="logo cursor-pointer  flex items-center gap-10"
        >
          <Link href="/home">
            {AuthState.userData?.student_token &&
              (scroll ? (
                <img className="h-20 w-auto" src={logoLight} alt="logo-dark" />
              ) : currentTheame === "dark" ? (
                <img className="h-20 w-auto" src={logoLight} alt="logo-dark" />
              ) : (
                <img className="h-20 w-auto" src={logoLight} alt="logo-light" />
              ))}
            {pathname === "/" && (
              <img className="h-20 w-auto" src={logoLight} alt="logo-light" />
            )}
          </Link>
        </div>

        <ul className="nav-links flex h-full w-fit items-center justify-center gap-16 amd:hidden ">
          {AuthState.userData?.student_token && (
            <>
              <div className="nav-item relative text-2xl  font-bold">
                <li>
                  <NavLink
                    className={`${
                      pathname.includes("/home") &&
                      "p-5 bg-secondary text-white rounded-3xl"
                    } ${
                      scroll && pathname.includes("/home")
                        ? "!bg-accent !text-white"
                        : ""
                    }`}
                    to="/home"
                  >
                    Home
                  </NavLink>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li>
                  <NavLink
                    className={`${
                      pathname.includes("/lectures") &&
                      "p-5 bg-secondary text-white rounded-3xl"
                    } ${
                      scroll && pathname.includes("/lectures")
                        ? "!bg-accent !text-white"
                        : ""
                    }`}
                    to="/lectures"
                  >
                    Lectures
                  </NavLink>
                </li>
              </div>
              {/* <div className="nav-item relative text-2xl  font-bold">
                  <li>
                    <NavLink
                      className={`underline-gradient dark:text-white ${
                        scroll && "!text-white"
                      }`}
                      to="/home/question-bank"
                    >
                      Question Bank
                    </NavLink>
                  </li>
                </div> */}
              <div className="nav-item relative text-2xl  font-bold ">
                <li>
                  <NavLink
                    className={`${
                      pathname.includes("/exams") &&
                      "p-5 bg-secondary text-white rounded-3xl"
                    } ${
                      scroll && pathname.includes("/exams")
                        ? "!bg-accent !text-white"
                        : ""
                    }`}
                    to="/exams"
                  >
                    Exams
                  </NavLink>
                </li>
              </div>
            </>
          )}
        </ul>

        <div className="nav__buttons-dark-btns flex items-center justify-end  gap-10 ">
          {AuthState.userData?.student_token ? (
            <>
              {/* <div className="sm:hidden">
                <label className="switch cursor-pointer ">
                  <span className="sun">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g>
                        <circle r="5" cy="12" cx="12"></circle>
                        <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
                      </g>
                    </svg>
                  </span>
                  <span className="moon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
                    </svg>
                  </span>
                  <input
                    type="checkbox"
                    className="dark-mode-checkbox"
                    value="dark"
                    onChange={handleToggleTheme}
                    defaultChecked={currentMode === "dark"}
                  />
                  <span className="slider"></span>
                </label>
              </div> */}
              <DropDownMenu scroll={scroll} />
            </>
          ) : (
            <>
              <ul className="nav-links flex h-full w-fit items-center justify-center gap-16 amd:hidden ">
                <Link
                  to="/signin"
                  className="nav-item relative text-4xl text-white uppercase"
                >
                  Sign In
                </Link>
                <Link
                  to="/register"
                  className="nav-item relative text-4xl text-white uppercase"
                >
                  Join Us
                </Link>
              </ul>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
