import { useEffect, useRef, useState } from "react";
import {
  useAuthState,
  useAuthDispatch,
} from "../../../../MainComponents/GlobalContext.jsx";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import AddEditPost from "./AddEditPost.jsx";

function Posts() {
  const AuthState = useAuthState();
  const dispatchAuth = useAuthDispatch();
  const navigate = useNavigate();

  const allPostsWrapper = useRef(null);
  const Modal = useRef(null);
  const [reFetch, setReFetch] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!---get posts data request
  const [postsDataSuccess, postsErrors, postsDataLoading] = useAxios(
    process.env.REACT_APP_ALL_DASHBOARD_POSTS_API,
    "GET",
    "GET",
    reFetch
  );

  //!--------- delete Question -------

  const [deleteQuestionSuccess, deleteQuestionErrors, deleteloading] = useAxios(
    process.env.REACT_APP_DELETE_DASHBOARD_POST_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  useEffect(() => {
    if (postsDataSuccess && allPostsWrapper) {
      displayPosts();
    }
  }, [postsDataSuccess, allPostsWrapper]);

  useEffect(() => {
    //! refetch the data on success delete Question
    if (deleteQuestionSuccess) {
      Modal.current.classList.add("hide");

      setReFetch(!reFetch);
      setDeleteId("");
    }
  }, [deleteQuestionSuccess]);

  function handleDelete() {
    setSubmitDelete({
      flag: "deleteQuestion",
      dependency: !submitDelete.dependency,
      data: { id: deleteId },
    });
  }
  function displayPosts() {
    /* if (postsErrors && !postsDataSuccess) {
      document.querySelector(".singleQuestionWrapper")?.remove();
      return;
    } */
    postsDataSuccess?.data?.forEach((post) => {
      //!--create containers
      let singleQuestionWrapper = document.createElement("div");
      singleQuestionWrapper.classList.add("singleQuestionWrapper");
      //!-----------
      let optionsQuestionWrapper = document.createElement("div");
      optionsQuestionWrapper.classList.add("optionsPostWrapper");
      //!-----------
      let questionOptions = document.createElement("div");
      questionOptions.classList.add("postOptions");
      //!----------- delete btn
      let deleteQuestionBtn = document.createElement("button");
      deleteQuestionBtn.classList.add("deleteQuestionBtn");
      deleteQuestionBtn.innerText = "Delete";
      const selectDialog = document.getElementById("dialog");
      deleteQuestionBtn.addEventListener("click", () => {
        Modal.current.classList.remove("hide");
        setDeleteId(post?.key);
      });
      //!----------- edit btn
      let editQuestionBtn = document.createElement("button");
      editQuestionBtn.classList.add("editQuestionBtn");
      editQuestionBtn.innerText = "Edit";
      editQuestionBtn.addEventListener("click", () =>
        navigate(`/admin/dashboard/edit-post/${post?.key}`)
      );

      //!----------- edit delete permissions

      if (
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("post_delete")
        )
      ) {
        questionOptions.append(deleteQuestionBtn);
      }
      if (
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("post_edit")
        )
      ) {
        questionOptions.append(editQuestionBtn);
      }
      //!----------- PostWrapper
      let PostWrapper = document.createElement("div");
      PostWrapper.classList.add("PostWrapper");
      let questionTitle = document.createElement("p");
      questionTitle.innerText = ` Post #${post?.n} `;
      let postTime = document.createElement("h6");
      postTime.innerText = `${post?.created_at} `;
      let essayTitleWrapper = document.createElement("div");
      let postStage = document.createElement("h4");
      postStage.innerText = `${
        post?.stage === 1
          ? "First grade"
          : post?.stage === 2
            ? "Second grade"
            : post?.stage === 3
              ? "Third grade"
              : ""
      }`;
      essayTitleWrapper.append(postStage);

      essayTitleWrapper.append(postTime);

      essayTitleWrapper.append(questionTitle);
      essayTitleWrapper.classList.add("essayTitleWrapper");
      let postContent = document.createElement("div");
      postContent.innerHTML = `${post?.content}`;
      //!--remove the first child sent by the text editor
      if (
        postContent.childNodes.length > 0 &&
        postContent.firstChild.nodeType === 1
      ) {
        postContent.removeChild(postContent.firstChild);
      }

      PostWrapper.append(essayTitleWrapper, postContent);
      //!-----------
      //!--append the options and the question to their parent div
      optionsQuestionWrapper.append(questionOptions, PostWrapper);

      //!--append the children to the parents
      singleQuestionWrapper.append(optionsQuestionWrapper);

      allPostsWrapper?.current?.appendChild(singleQuestionWrapper);
    });
  }
  if (postsDataLoading) {
    return <Loader />;
  }
  if (
    !AuthState.userData?.admin?.roles[0]?.name === "superadmin" &&
    !AuthState.userData?.admin?.permissions?.some((permission) =>
      permission.name.startsWith("post")
    )
  ) {
    return (
      <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
        <HelmetTags title="Posts | Mr Ahmed Sabry"></HelmetTags>
        <h2 className="w-full text-4xl font-bold md:text-center mb-12">
          Posts
        </h2>
      </section>
    );
  }
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20  dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Posts | Mr Ahmed Sabry"></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center mb-12">Posts</h2>
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some(
          (permission) =>
            permission.name.startsWith("post_add") &&
            permission.name.startsWith("post")
        )) && <AddEditPost setReFetch={setReFetch} reFetch={reFetch} />}

      <div
        ref={Modal}
        className="modal hide absolute bottom-1/2 right-1/2  w-[320px] translate-x-1/2  translate-y-1/2 flex-col items-center gap-4 rounded-2xl bg-blue-200 p-4"
      >
        <p className="mb-7 w-full text-center">
          Do you really want to delete the post?
        </p>
        <div className="flex items-center justify-center gap-7">
          <button
            onClick={() => {
              Modal.current.classList.add("hide");
              setDeleteId("");
            }}
            className="closeModal "
          >
            Cancel
          </button>
          <button onClick={handleDelete} className="confirmModal">
            Confirm
          </button>
        </div>
      </div>
      {postsErrors && typeof postsErrors === "string" ? (
        <div className="w-full text-center mt-14">
          {postsErrors && typeof postsErrors === "string" && (
            <p className="w-full text-center">{postsErrors}</p>
          )}
        </div>
      ) : (
        <div
          ref={allPostsWrapper}
          className="all-questions flex h-auto w-full flex-col gap-20 px-16 amd:px-0 mt-16"
        ></div>
      )}
    </section>
  );
}

export default Posts;
