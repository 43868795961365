import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AdminAddStudentLecture() {
  const { lectureID } = useParams();
  const navigate = useNavigate();
  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddStudentToLecture": {
        return {
          ...state,
          submitAddStudentToLecture: action.payload,
        };
      }

      case "setSubmitAddStudentToLectureById": {
        return {
          ...state,
          submitAddStudentToLectureById: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddStudentToLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
    submitAddStudentToLectureById: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [
    AddStudentToLectureSuccess,
    AddStudentToLectureErrors,
    AddStudentToLectureLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_STUDENT_TO_LECTURE_API,
    "POST",
    state.submitAddStudentToLecture.flag,
    state.submitAddStudentToLecture.dependency,
    state.submitAddStudentToLecture.data,
    true
  );

  const [
    AddStudentToLectureByIdSuccess,
    AddStudentToLectureByIdErrors,
    AddStudentToLectureByIdLoading,
  ] = useAxios(
    "https://api.mrahmedsabry.com/api/admin/lecture/addstudentById",
    "POST",
    state.submitAddStudentToLectureById.flag,
    state.submitAddStudentToLectureById.dependency,
    state.submitAddStudentToLectureById.data,
    true
  );

  useEffect(() => {
    if (AddStudentToLectureByIdSuccess) {
      navigate(-1);
    }
    if (AddStudentToLectureSuccess) {
      navigate(-1);
    }
  }, [AddStudentToLectureSuccess, navigate, AddStudentToLectureByIdSuccess]);

  const {
    register: registerMobile,
    handleSubmit: handleSubmitMobile,
    formState: { errors: mobileErrors, isValid: isMobileValid },
  } = useForm({ mode: "onTouched" });

  const {
    register: registerStudentID,
    handleSubmit: handleSubmitStudentID,
    formState: { errors: studentIdErrors, isValid: isStudentIdValid },
  } = useForm({ mode: "onTouched" });

  const onSubmitMobile = (data) => {
    const finalData = {
      ...data,
      lecture_id: lectureID,
    };
    dispatch({
      type: "setSubmitAddStudentToLecture",
      payload: {
        flag: "AddStudentToLecture",
        dependency: !state.submitAddStudentToLecture.dependency,
        data: finalData,
      },
    });
  };

  const onSubmitByStudentId = (data) => {
    const finalData = {
      id: data.studentID,
      lecture_id: lectureID,
    };

    dispatch({
      type: "setSubmitAddStudentToLectureById",
      payload: {
        flag: "AddStudentToLectureById",
        dependency: !state.submitAddStudentToLectureById.dependency,
        data: finalData,
      },
    });
  };
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Add Student | Mr.Ahmed Sabry"></HelmetTags>

      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Please fill this input to add student
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmitMobile(onSubmitMobile)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-3xl"
        >
          <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
            <label htmlFor="mobile">Mobile Number</label>
            <input
              className="signin-inputs w-full pl-4"
              type="number"
              id="mobile"
              placeholder="Mobile Number"
              name="mobile"
              autoComplete="on"
              {...registerMobile("mobile", {
                required: true,
                maxLength: 40,
                minLength: 3,
              })}
            />
            {mobileErrors.mobile && (
              <p className="w-full text-end text-[12px] text-red-900">
                {mobileErrors.mobile.type === "required" &&
                  "This field is required"}
              </p>
            )}

            {AddStudentToLectureErrors?.response?.data?.errors?.mobile && (
              <p className="w-full text-end text-[12px] text-red-900">
                {AddStudentToLectureErrors?.response?.data?.errors?.mobile[0]}
              </p>
            )}
          </div>

          <button
            disabled={!isMobileValid || AddStudentToLectureLoading}
            className="submit mt-6 w-full"
            type="submit"
          >
            {AddStudentToLectureLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>Add Student by Mobile</p>
            )}
          </button>
        </form>
      </div>

      <div className="form-container my-20 flex w-full flex-col items-center">
        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmitStudentID(onSubmitByStudentId)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-3xl"
        >
          <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
            <label htmlFor="id">Student ID</label>
            <input
              className="signin-inputs w-full pl-4"
              type="text"
              id="id"
              placeholder="Student ID"
              name="id"
              autoComplete="on"
              {...registerStudentID("studentID", {
                required: true,
                maxLength: 40,
                minLength: 2,
              })}
            />
            {/*   {studentIdErrors.studentID && (
              <p className="w-full text-end text-[12px] text-red-900">
                {studentIdErrors.studentID.type === "required" &&
                  "This field is required"}
              </p>
            )} */}

            {AddStudentToLectureByIdErrors?.response?.data?.errors?.mobile && (
              <p className="w-full text-end text-[12px] text-red-900">
                {AddStudentToLectureByIdErrors?.response?.data?.errors}
              </p>
            )}
          </div>

          <button
            disabled={!isStudentIdValid || AddStudentToLectureByIdLoading}
            className="submit mt-6 w-full"
            type="submit"
          >
            {AddStudentToLectureByIdLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>Add Student by ID</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AdminAddStudentLecture;
