import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function Students({ type }) {
  const [academiclevel, setAcademiclevel] = useState(null);
  const [academicType, setAcademicType] = useState(null);

  const url = `${academiclevel}/${type === "online" ? "1" : "0"}`;
  const tableFor = type === "online" ? "onlineStudents" : "centerStudents";

  const table = useTable(
    academicType && academiclevel ? tableFor : null,
    "",
    "",
    url,
    "",
    url
  );

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20  dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`${
          type === "online" ? "Online" : "Center"
        } Students | Mr Ahmed Sabry`}
      ></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <div className="w-full flex items-center justify-between gap-4">
          <h2 className="flex-1 text-4xl text-left font-bold md:text-center">
            {type === "online" ? "Online" : "Center"} Students
          </h2>
          <Link
            to={`/admin/${
              type === "online" ? "online" : "center"
            }-students/add-student`}
            className={`rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 bg-secondary text-light flex items-center gap-6`}
          >
            <FontAwesomeIcon icon={faPlus} />
            Add Student
          </Link>
        </div>

        <div className="flex w-full  gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademicType("national");
              setAcademiclevel("");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicType === "national" && "bg-secondary text-light"
            }`}
          >
            National
          </button>

          <button
            onClick={() => {
              setAcademicType("ig");
              setAcademiclevel("");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicType === "ig" && "bg-secondary text-light"
            }`}
          >
            IG
          </button>
        </div>
        {academicType === "national" && (
          <div className="flex w-full  gap-10 md:justify-center md:flex-col sm:items-center">
            <button
              onClick={() => {
                setAcademiclevel("22");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "22" && "bg-secondary text-light"
              }`}
            >
              Second preparatory
            </button>
            <button
              onClick={() => {
                setAcademiclevel("33");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "33" && "bg-secondary text-light"
              }`}
            >
              Third Preparatory
            </button>

            <button
              onClick={() => {
                setAcademiclevel("1");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "1" && "bg-secondary text-light"
              }`}
            >
              First Secondary
            </button>

            <button
              onClick={() => {
                setAcademiclevel("2");
              }}
              className={` rounded-xl border-2 border-secondary    p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "2" && "bg-secondary text-light"
              }`}
            >
              Second Secondary
            </button>

            <button
              onClick={() => {
                setAcademiclevel("3");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "3" && "bg-secondary text-light"
              }`}
            >
              Third Secondary
            </button>
          </div>
        )}
        {academicType === "ig" && (
          <div className="flex w-full  gap-10 md:justify-center  md:flex-col sm:items-center">
            <button
              onClick={() => {
                setAcademiclevel("9");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "9" && "bg-secondary text-light"
              }`}
            >
              Nine Year
            </button>
            <button
              onClick={() => {
                setAcademiclevel("10");
              }}
              className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academiclevel === "10" && "bg-secondary text-light"
              }`}
            >
              Ten Year
            </button>
          </div>
        )}
      </div>
      {/**
       * //!---------Table-----
       */}
      {academiclevel && academicType ? (
        <div className="h-fit  w-full">{table}</div>
      ) : null}
    </section>
  );
}

export default Students;
